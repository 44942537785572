<template>
  <div class="banner__container mt-5 mb-5">
    <div class="banner__main_two h-auto">
      <div
        class="generic__info main__info--top main__info--top-big generic__info--top brd-top__hide brd-left__hide"
      >
        <div class="main-counter">
          <div class="empty"></div>
        </div>
      </div>
     

      <div class="gap"></div>
      <div class="text-center px-5">
        <h3>EARNING PROGRAM</h3>
        <p class="mt-5 w-75 m-auto">The Wavicle Earnings Program allows holders of Wavicle Tracks to earn a percentage of the revenue generated from sales on the secondary market. By owning a Wavicle Track, you are entitled to receive 0.1% of the sales revenue from any Wavicle Debut Collection NFTs sold on the secondary market.</p>
      </div>
      <div class="gap"></div>

      <div
            class="generic__info banner__info--bottom-left brd-bottom__hide brd-left__hide brd-left__hide"
          >
            <div class="main-counter"></div>
          </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Earning",
};
</script>

<style></style>
