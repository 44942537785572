<template>
   <div class="banner__container mt-5">
      
      <div class="banner__main_two">
        <div
        class="generic__info banner__info generic__info--top banner__info--top ps-5 pb-1 pt-1 brd-top__hide brd-right__hide"
      >
      <div class="main-counter">
            <h5>how it works</h5>
          </div>
        </div>
        <div class="buble buble-top_medium"></div>
     
        <div class="container-fluid ps-5 pe-5 pt-5">
            <div class="row">
                <div class="col-5">
                    <p>With Wavicle, you can collect audio layer non-fungible tokens (NFTs) and use them to create your own unique musical combinations. Our platform offers a new and exciting way to collect and enjoy music, while providing artists with a new revenue stream for their work. Listeners will have a much more immersive experience with Wavicle, as they will have access to the final cut of the music and control over the production process.</p>
                </div>
                <div class="col-6 offset-1 text-center">
                    <img src="../assets/image/nero 1.png" alt="">
                </div>
            </div>
        </div>
      
      </div>
   
  </div>
</template>

<script>
export default {
    name:"Works"
}
</script>

<style>

</style>