<template>
  <header class="header--spinnin2">
    <div class="header__wrap">
      <div class="nav--header">
        <ul>
          <li><a href="#">about</a></li>
          <li class="mr-lf-40"><a href="#">packs</a></li>
        </ul>
        <ul>
          <li><a href="#">connect wallet</a></li>
        </ul>
      </div>

      <a href="/" class="header__logo"
        ><img src="../assets/image/wavicle.svg" alt=""
      /></a>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style></style>
