<template>
    <div class="intro mt-5 pt-5">
      <div class="banner__container">

          <div class="banner__main ">
            <div
              class="generic__info main__info--top generic__info--top brd-top__hide brd-left__hide"
            >
              <div class="main-counter">
                <h5>what's?</h5>
              </div>
            </div>
            <div>
                <div class="buble buble-top"></div>
                <img class="buble-img" src="../assets/image/balls/big-ball.svg" alt="">
            </div>
            
            <div class="container-fluid ps-5 pe-5">
                <div class="row">
                    <div class="col-6">
                        <h6>
                            create <br> collect <br> share
                        </h6>
                    </div>
                    <div class="col-10 mt-5">
                        <p>Wavicle is a new and innovative platform that uses cutting-edge web3 technologies to unlock a world of musical possibilities. Our platform is based on curated, layered collections of musical elements composed by groups of carefully selected artists. This allows artists to transfer their creative vision into the hands of collectors, and enables users to develop innovative methods of artistic experience and expression.</p>
                    </div>
                </div>
            </div>
            <div class="buble buble-bottom"></div>

        </div>
    
      </div>
     
    </div>
  </template>
  
  <script>
  export default {
    name: "What",

  };
  </script>
  
  <style></style>
  