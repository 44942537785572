<template>
    <div class="mt-5 pt-5">
      <div class="container-fluid">
        <div class="row">
            <div class="col-6 p-5">
              <div class="text-center">
                <img src="../assets/image/Dots.png" alt="">
              </div>
          </div>
          <div class="col-6">
            <div class="banner__container">
              <div class="banner__main_two h-auto pb-5">
                <div
                  class="generic__info main__info--top generic__info--top brd-top__hide brd-left__hide"
                >
                  <div class="main-counter">
                    <div class="empty"></div>
                
                  </div>
                </div>
               
  
                <div class="gap"></div>
                
                <div class="p-5">
                    <p>
                        This benefit is cumulative, so if you own 10 Wavicle Tracks, you are entitled to receive 1% of the sales revenue from any Wavicle Debut Collection NFTs sold on the secondary market.
                    </p>
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Benefit",

  };
  </script>
  
  <style></style>
  