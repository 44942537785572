<template>
  <div class="home">
    <Header />
    <Intro @sectionClick="onSectionClick" />
    <div v-if="active">
      <What />
      <Works />
      <Wavicle />
      <Banner />
      <SectionText />
      <Earning />
      <Benefit />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Header'
import Intro from '../components/Intro'
import What from '../components/What'
import Works from '../components/Works'
import Wavicle from '../components/Wavicle'
import Banner from '../components/Banner'
import SectionText from '../components/SectionText'
import Earning from '../components/Earning'
import Benefit from '../components/Benefit'

export default {
  name: 'Home',
  data(){
    return{
      active:false
    }
  },
  components: {
    Header,
    Intro,
    What,
    Works,
    Wavicle,
    Banner,
    SectionText,
    Earning,
    Benefit
  },
  methods: {
    onSectionClick() {
      this.active = true
    }
  },
}
</script>
<style scoped>

</style>
