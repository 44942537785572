<template>
  <div class="mt-5 pt-5">
    <div class="banner__container">
      <div class="banner__main_two h-auto container-text min-h-container">
        <img
          class="ball-float"
          src="../assets/image/balls/big-ball.svg"
          alt=""
        />
        <img
          class="ball-float"
          src="../assets/image/balls/big-ball-purple.svg"
          alt=""
        />
        <img
          class="ball-float"
          src="../assets/image/balls/medium-ball-violet.svg"
          alt=""
        />
        <img
          class="ball-float"
          src="../assets/image/balls/small-ball.svg"
          alt=""
        />
        <div class="px-5 py-2">
          <p class="big-text">
            TEN special type of Wavicle Layer that are exclusive to the debut
            collection. These rare layers can be used to add even more
            uniqueness and value to a Wavicle Track AND they cannot be purchased
            individually on the secondary market.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionText",
  mounted() {
    const bubbles = document.querySelectorAll(".ball-float");

    function animateBubble(bubble) {
  const container = document.querySelector(".container-text");
  const containerRect = container.getBoundingClientRect();
  const bubbleRect = bubble.getBoundingClientRect();
  let top = bubbleRect.top - containerRect.top;
  let left = bubbleRect.left - containerRect.left;
  const direction = Math.random() * 360;
  const distance =
    Math.random() * (containerRect.height + containerRect.width);
  const x = Math.sin(direction) * distance;
  const y = Math.cos(direction) * distance;
  const maxX = containerRect.width - bubbleRect.width;
  const maxY = containerRect.height - bubbleRect.height;
  let vx = (x / distance) * (Math.random() + 0.5);
  let vy = (y / distance) * (Math.random() + 0.5);
  let hasBounced = false;
  bubble.animate(
    [
      { top: `${top}px`, left: `${left}px` },
      { top: `${top + y}px`, left: `${left + x}px` },
      { top: `${top}px`, left: `${left}px` },
    ],
    {
      duration: 16000 + Math.random() * 16000,
      iterations: Infinity,
      easing: "linear",
      updateTiming: "auto",
      onfinish() {
        hasBounced = false;
      },
      update() {
        if (hasBounced) return;
        top += vy;
        left += vx;

        // Rimbalzo verticale
        if (top <= 0) {
          top = 0;
          vy = Math.abs(vy) * (Math.random() + 0.5);
          hasBounced = true;
        } else if (top >= maxY) {
          top = maxY;
          vy = -Math.abs(vy) * (Math.random() + 0.5);
          hasBounced = true;
        }

        // Rimbalzo orizzontale
        if (left <= 0) {
          left = 0;
          vx = Math.abs(vx) * (Math.random() + 0.5);
          hasBounced = true;
        } else if (left >= maxX) {
          left = maxX;
          vx = -Math.abs(vx) * (Math.random() + 0.5);
          hasBounced = true;
        }

        bubble.style.top = `${top}px`;
        bubble.style.left = `${left}px`;
      },
    }
  );
}


    bubbles.forEach(animateBubble);
  },
};
</script>

<style></style>
