<template>
  <div class="mt-5 pt-5">
    <div class="container-fluid">
      <div class="row">
        <div class="col-6">
          <div class="banner__container pe-0">
            <div class="banner__main_two min-h">
              <div
                class="generic__info main__info--top generic__info--top brd-top__hide brd-left__hide"
              >
                <div class="main-counter">
                  <div class="empty"></div>
                </div>
              </div>
              <div>
                <div class="buble buble-top"></div>
              </div>

              <div class="marker-box">
                <h2 @click="active= 'debut'" :class="{ 'active-marker': active === 'debut' }">debut</h2>
                <h2  @click="active = 'kits'" :class="{ 'active-marker': active === 'kits' }">kits</h2>
                <h2  @click="active = 'layers'" :class="{ 'active-marker': active === 'layers' }">layers</h2>
                <h2  @click="active = 'tracks'" :class="{ 'active-marker': active === 'tracks' }">tracks</h2>
              </div>
              <div class="buble buble-bottom"></div>
            </div>
          </div>
        </div>
        <div class="col-6 p-5">
            <div v-if="active == 'default'" class="d-flex justify-content-center align-items-center flex-wrap h-100">
              <h2 class="w-75 p-1 default-box">
                ??????????????????
              </h2>
            </div>
            <div v-if="active == 'debut'" class="ps-3 pe-3 ">
              <h6 class="mb-5">WAVICLE DEBUT COLLECTION</h6>
              <p>The Wavicle Debut Collection is the first collection of musical layers and tracks created as part of the Wavicle project. It brings together ten talented artists to create a total of 50 unique musical layers, separated into five categories: Drum, Bass, Lead, Harmony, and Perc+Fx. These layers can be combined by collectors to create one-of-a-kind Wavicle Tracks, each with its own unique traits and accompanying artwork</p>
            </div>
            <div v-if="active == 'kits'" class="ps-3 pe-3 ">
              <h6  class="mb-5">WAVICLE kits</h6>
              <p>are non-fungible tokens (NFTs) that contain five Layer NFTs, one for each of the required categories: DRUM, BASS, LEAD, HARMONY, and PERC+FX. Only 1000 Wavicle Kits will be available for minting in the debut collection. <br><br> There is also a chance that a Wavicle Kit may contain one of the exclusive Legendary Layers, which can be used to add even more uniqueness and value to a Wavicle Track.</p>
            </div>
            <div v-if="active == 'layers'" class="ps-3 pe-3 ">
              <h6  class="mb-5">Wavicle Layers</h6>
              <p>Wavicle Layers are non-fungible tokens (NFTs) that represent individual musical components, such as drum beats, bass lines, or lead melodies. These layers are created by the participating artists as part of the Wavicle Debut Collection and can vary in rarity. Collectors can choose to collect or trade these layers, or use them to create a unique Wavicle Track. The debut collection includes 50 types of Wavicle Layers, with 5000 editions of each, which can range in rarity.</p>
            </div>
            <div v-if="active == 'tracks'" class="ps-3 pe-3 ">
              <h6  class="mb-5">Wavicle Tracks</h6>
              <p>A Wavicle Track is a one-of-a-kind non-fungible token (NFT) that represents ownership of a full-length, unique song, complete with its own accompanying artwork and rarity traits. To create a Wavicle Track, a collector must own five corresponding Layer NFTs, one from each of the required categories: Drum, Bass, Lead, Harmony, and Perc+Fx.</p>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Wavicle",
  data(){
    return{
      active: "default"
    }
  }
};
</script>

<style></style>
