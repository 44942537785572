import Vue from 'vue'
import App from './App.vue'
import router from './router'
import MarqueeText from "vue-marquee-text-component";

import "@/themes/style.scss";
import "@/themes/resp.scss";
import "@/themes/animation.scss";
import '@/assets/fonts/font.css'

Vue.config.productionTip = false
Vue.component("marquee-text", MarqueeText);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
