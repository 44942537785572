<template>
  <div class="intro mt-5 pt-5">
    <div class="banner__container">
     
        <div class="banner__main">
          <div
            class="generic__info banner__info generic__info--top banner__info--top brd-top__hide brd-right__hide"
          >
            <div class="banner-counter"></div>
          </div>
          <div class="box-intro">
            <img class="logo-big" src="../assets/image/w_big.svg" alt="" />
            <div v-if="!active" class="box-name-animation">
                <img src="../assets/image/Group 2.png" alt="">
            </div>
            <div v-if="active" :class="{ 'w-100': active }" class="box-name-animation">
              <div class="box-img">
                <img
                  :class="{ 'active-animation': active }"
                  class="character_w"
                  src="../assets/image/text/character_w.svg"
                  alt=""
                />
              </div>

              <div class="box-img">
                <img
                  :class="{ 'active-animation': active }"
                  class="character_a"
                  src="../assets/image/text/character_a.svg"
                  alt=""
                />
              </div>

              <div class="box-img">
                <img
                  :class="{ 'active-animation': active }"
                  class="character_v"
                  src="../assets/image/text/character_v.svg"
                  alt=""
                />
              </div>

              <div class="box-img">
                <img
                  :class="{ 'active-animation': active }"
                  class="character_i"
                  src="../assets/image/text/character_i.svg"
                  alt=""
                />
              </div>

              <div class="box-img">
                <img
                  :class="{ 'active-animation': active }"
                  class="character_c"
                  src="../assets/image/text/character_c.svg"
                  alt=""
                />
              </div>

              <div class="box-img">
                <img
                  :class="{ 'active-animation': active }"
                  class="character_l"
                  src="../assets/image/text/character_l.svg"
                  alt=""
                />
              </div>

              <div class="box-img">
                <img
                  :class="{ 'active-animation': active }"
                  class="character_e"
                  src="../assets/image/text/character_e.svg"
                  alt=""
                />
              </div>
            </div>
            <!-- <div>
                        <img src="../assets/image/Group 2.png" alt="">
                    </div> -->
            <div class="mb-5">
              <button class="btn-intro" v-if="!active" @click="onSectionClick()">Discover</button>
            </div>
          </div>
          <div
            class="banner__info generic__info banner__info--bottom brd-bottom__hide brd-right__hide"
          >
            <div class="banner-counter"></div>
          </div>
        </div>
     
    </div>
  </div>
</template>

<script>
export default {
  name: "Intro",
  data() {
    return {
      active: false,
    };
  },
  methods:{
    onSectionClick() {
        this.active = true
        this.$emit("sectionClick");
      
    },
  }
};
</script>

<style></style>
