<template>
  <div class="banner">
    <MarqueeText
      class="title-project"
      :repeat="6"
      :duration="10"
    >
      <h2>legendary layers&nbsp;</h2>
    </MarqueeText>
  </div>
</template>

<script>
import MarqueeText from "vue-marquee-text-component";
export default {
    name:"Banner",
    components:{
        MarqueeText
    }
}
</script>

<style>

</style>